import { io } from "socket.io-client";
// import Cookies from "universal-cookie";
import { getSessionData } from "../utils/session";

// function getLocalStorage() {
//   const response = JSON.parse(localStorage.getItem("yp_dadosLogin"));
//   return response;
// }

// const cookies = new Cookies();

// let dadosLogin = cookies.get("yp_dadosLogin");
// if (!dadosLogin) {
//   dadosLogin = getLocalStorage();
// }

const socket = io("https://ws.yetzpromo.com.br", {
  autoConnect: false,
  // extraHeaders: {
  //   Authorization: `Bearer ${dadosLogin?.token?.access_token}`,
  // },
});

function getSocket() {
  const dadosLogin = getSessionData();

  const extraHeaders = dadosLogin
    ? {
        Authorization: `Bearer ${dadosLogin.token.access_token}`,
      }
    : undefined;

  return io("https://ws.yetzpromo.com.br", {
    autoConnect: true,
    extraHeaders,
  });
}

export { socket, getSocket };
