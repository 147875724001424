import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #fff;
  min-height: max(400px, 100vh);
  @media (min-width: 980px) {
    min-height: 600px;
  }
`;

export const InnerContainer = styled.div`
  max-width: 362px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: max(400px, 100vh);

  @media (min-width: 980px) {
    min-height: 600px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-left: 25px;
  padding-right: 25px;
`;

export const ImageWrapper = styled.div`
  padding-left: 4px;
  padding-top: 44px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 20px;
  padding: 0 25px 25px 25px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Title = styled.h1`
  color: #000;
  font-family: "SF Pro Text";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.96px;
  margin-bottom: 0px;
  margin-top: 15px;
`;

export const Message = styled.p`
  color: #4C4C4C;
  font-family: "SF Pro Text";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.32px;
  margin-top: 12px;
`;

export const ContactButton = styled.button`
  background-color: #ff6a00;
  color: white;
  height: 48px;
  max-width: 312px;
  width: 100%; 
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 12px;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "SF Pro Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  &:hover {
    background-color: #e65a00;
  }
`;
