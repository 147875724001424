/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Link, Navigate, useHref, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Cookies from "universal-cookie";

import { api } from "../../../services/api";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import Footer from "../../../components/footer";
import Button from "../../../components/button";
import ModalGrupoLista from "../../../components/modal/grupoLista";
import ModalAguardeEmissao from "../../../components/modal/aguardeEmissao";
import ModalAviso from "../../../components/modal/aviso";
import MenuLateral from "../../../components/menuLateral";
import { When } from "../../../components/when";
import { RenderLogo } from "../../../components/logos";
import { ModalGrupo } from "../../../components/modal/modalgrupo";
import ModalGrandCru from "../../../components/modal/modalGrandCru";

import { Container } from "./styles";
import { ClickAjuda } from "../../principal/styles";

import img1 from "../../../assets/img/timer-branco.svg";
import img2 from "../../../assets/img/cadeado.svg";
import img3 from "../../../assets/img/mensagem.svg";
import img4 from "../../../assets/img/calendario.svg";
import img5 from "../../../assets/img/sair.svg";
import img6 from "../../../assets/img/calendar.svg";
import img7 from "../../../assets/img/olho.svg";
import ContactUsIcon from "../../../assets/img/ContactUsIcon.svg";
// import img8 from "../../../assets/img/menu-branco.svg";
import imgLoader from "../../../assets/img/loader-yetz-3.svg";

import { useAcaoContext } from "../../../contexts/acaoContext";
import { ModalSaibaMais } from "../../../components/modal/saibaMais";
import { ModalSaibaMaisNovo } from "../../../components/modal/saibaMaisNovo";
import Modal from "../../../components/NewModal/center";
import { ModalNovidade } from "../../../components/modal/Novidade";
import {
  getLocalStorageShowDigitalWallet,
  setLocalStorageShowDigitalWallet,
} from "../../../services/helper";

export function Loja() {
  const navigate = useNavigate();
  const cookies = new Cookies();
  let user = cookies.get("yp_user");
  if (!user) {
    user = JSON.parse(localStorage.getItem("yp_user"));
  }
  const dadosLogin = cookies.get("yp_dadosLogin");
  let paramProduto = cookies.get("yp_paramProduto");
  if (!paramProduto) {
    paramProduto = localStorage.getItem("yp_paramProduto");
  }
  const {
    configuracao,
    setProductsGroup,
    configLoja,
    setConfigLoja,
    produtos,
    setProdutos,
  } = useAcaoContext();

  const isStone2024 = configuracao?.key === "STONE2024";

  const baseUrl = `/${configuracao?.key}`;
  const origin_benefit_click_id = configuracao?.origin_benefit_click_id;
  const linkUrl =
    origin_benefit_click_id && origin_benefit_click_id !== ""
      ? `${baseUrl}?origin_benefit_click_id=${origin_benefit_click_id}`
      : baseUrl;

  const [loading, setLoading] = useState(false);

  const [openModalEmissao, setOpenModalEmissao] = useState(false);
  const [opendGroup, setOpendGroup] = useState(false);
  const [opendGroupList, setOpendGroupList] = useState(false);
  const [modalSaibaMais, setModalSaibaMais] = useState(false);

  const [produtoResgate, setProdutoResgate] = useState();
  const [productGroup, setProductGroup] = useState();
  const [isGroup, setIsGroup] = useState(false);

  const [activeMenu, setActiveMenu] = useState(false);

  const [active, setActive] = useState(false);
  const [modalNovidade, setModalNovidade] = useState(false);
  const [mensagem, setMensagem] = useState();

  const [modalSaibaMaisNovo, setModalSaibaMaisNovo] = useState(false);
  const [produtoSaibaMaisNovo, setProdutoSaibaMaisNovo] = useState();
  const path = useHref().split("/")[1];

  const [openModalGrandCru, setOpenModalGrandCru] = useState(false);
  const [dataGrandCru, setDataGrandCru] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .post(`/client/action/store`, {
        action_id: configuracao?.id,
        user_id: user?.user_id,
      })
      .then((data) => {
        setLoading(false);

        const storeProducts = data.products ?? [];

        setProdutos(storeProducts);
        setConfigLoja(data);

        let productFound = false;

        if (!loading) {
          if (paramProduto) {
            const swiperContainer = document.querySelector(
              ".loja-container .swiper"
            );
            if (swiperContainer) {
              const swip = swiperContainer.swiper;
              swip.slideTo(1);
            }

            storeProducts.forEach((p, index) => {
              if (productFound) return;

              if (p.is_group) {
                let setSlideTo = false;

                p.products.forEach((prod) => {
                  if (prod.info.search_key === paramProduto) {
                    if (swiperContainer) {
                      const swip = swiperContainer.swiper;
                      swip.slideTo(index);
                      setSlideTo = true;
                    }

                    productFound = true;
                    if (
                      configuracao?.choose_reward_show_subcarrossel_as_list &&
                      p.is_group
                    ) {
                      openGroupList(p);
                      setIsGroup(true);
                      return;
                    }
                  }
                  if (!setSlideTo) {
                    nextSlide();
                  }
                });
              } else {
                if (p.search_key === paramProduto) {
                  if (swiperContainer) {
                    const swip = swiperContainer.swiper;
                    swip.slideTo(index);
                  }

                  productFound = true;
                } else {
                  nextSlide();
                }
              }
            });

            if (!productFound) {
              nextSlide();
            }
          } else {
            nextSlide();
          }
        }
      })
      .catch((error) => {
        toast.error("Algo deu errado! Espere um pouco e recarregue a página.");
        console.log(error);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function nextSlide() {
    const swiperContainer = document.querySelector(".loja-container .swiper");
    if (swiperContainer) {
      const swip = swiperContainer.swiper;
      const { innerWidth: width } = window;

      if (width > 480) {
        swip.slideTo(1);
      } else {
        swip.slideTo(0);
      }
    }
  }

  function openResgate(prod) {
    setProdutoResgate(prod);
    setOpenModalEmissao(true);
  }

  function openGroup(prod) {
    setProductGroup(prod);
    setOpendGroup(true);
  }

  function openGroupList(prod) {
    setProductGroup(prod);
    setOpendGroupList(true);
  }

  function openSaibaMaisNovo(prod) {
    setProdutoSaibaMaisNovo(prod);
    setModalSaibaMaisNovo(true);
  }

  function getClassName() {
    let className = "container";

    if (produtos.length === 1) {
      className += " unicoProduto";
    }
    if (dadosLogin?.max_rewards > 1) {
      className += " multiresgate";
    }

    return className;
  }

  function verResgates(resgates, variacao_id) {
    if (resgates === 0) {
      setMensagem("Você ainda não resgatou!");
      setActive(true);
    } else {
      navigate("/" + configuracao?.key + "/finalizacao", {
        state: { variacao_id: variacao_id },
      });
    }
  }

  function handleLogout() {
    localStorage.removeItem("timeCodeSending");
  }

  if (!user) {
    return <Navigate to={`/${configuracao?.key}`} replace />;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (configuracao.show_digital_wallet === 1) {
      if (!getLocalStorageShowDigitalWallet()?.show_digital_wallet) {
        setLocalStorageShowDigitalWallet();
        setModalNovidade(!modalNovidade);
      }
    }
  }, [configuracao.show_digital_wallet]);

  return (
    <>
      <Container
        backgroundColor={configuracao?.background}
        isStone2024={isStone2024}
      >
        <div className={getClassName()}>
          <div className="topo">
            <div className="logos">
              <RenderLogo {...configuracao} />
            </div>
            <div className="links-topo">
              {configuracao?.show_digital_wallet === 1 ? (
                <div
                  className="verResgatesCupom"
                  onClick={() =>
                    navigate("/" + configuracao?.key + "/carteira")
                  }
                >
                  <img src={img4} alt="" />
                  <p>Meus Vouchers</p>
                </div>
              ) : null}
              <Link to={linkUrl} onClick={handleLogout}>
                <img src={img5} alt="Sair" />
                <span>Sair</span>
              </Link>
            </div>
          </div>

          <div className="topo-titulo">
            <div className="texto-personalizado">
              <h2
                style={
                  isStone2024
                    ? {
                        fontFamily: "'TonCondensedVF', sans-serif",
                        fontWeight: "785",
                        color: "#003605",
                      }
                    : {}
                }
              >
                {configLoja?.general.choose_reward_title}
              </h2>
              <p>{configLoja?.general.choose_reward_subtitle}</p>

              {configuracao?.choose_reward_description !== null ? (
                <p className="descricao-titulo">
                  {configuracao?.choose_reward_description}
                </p>
              ) : null}
            </div>
            {configuracao?.use_coupons !== 1 ? (
              <div
                className="atencao"
                onClick={() =>
                  verResgates(configLoja?.user_reward_info.rewards)
                }
              >
                <img src={img4} alt="" />
                <p>
                  Você resgatou: {configLoja?.user_reward_info.rewards}/
                  {configLoja?.user_reward_info.max_rewards}
                </p>
              </div>
            ) : null}
          </div>
        </div>

        <div className="overlay-modal"></div>

        <div className={`${getClassName()} loja-container slide-container`}>
          <Swiper
            // install Swiper modules
            modules={[Navigation, Pagination]}
            centeredSlides={true}
            grabCursor={true}
            pagination={{ clickable: true }}
            navigation
            loop={true}
            breakpoints={{
              480: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
            }}
          >
            {loading ? (
              <div className="loader">
                <img src={imgLoader} alt="" />
              </div>
            ) : Array.isArray(produtos) && produtos.length > 0 ? (
              produtos.map((data, index) => {
                const isGroup = data.is_group;
                const img = isGroup ? data.image : data.img;
                const unavaliable_text = data.unavaliable_text;
                const format_unavaliable_text =
                  typeof unavaliable_text === "string"
                    ? unavaliable_text.split("#")
                    : [];

                const [text_a, text_b] = format_unavaliable_text;

                return (
                  <SwiperSlide key={index}>
                    <div className="premio">
                      <img src={img} alt="" width={301} height={306} />
                      <div className="name-product-or-group">
                        <h4
                          style={
                            isStone2024
                              ? {
                                  fontFamily: "'TonCondensedVF', sans-serif",
                                  fontWeight: "785",
                                  color: "#017F1CFA",
                                }
                              : {}
                          }
                        >
                          {data.name}
                        </h4>
                      </div>
                      <div className="name-description">
                        {data.name_description ? (
                          <p
                            style={
                              isStone2024
                                ? {
                                    fontFamily: "'TonCondensedVF', sans-serif",
                                    fontWeight: "400",
                                    color: "#000000",
                                  }
                                : {}
                            }
                          >
                            {data.name_description}
                          </p>
                        ) : null}
                      </div>

                      {data.stock_amount === 0 ? (
                        <div className="voucher-indisponivel">
                          <Button title="Voucher Indisponível" />
                          <span>
                            <img src={img1} alt="" />
                            {data.unavaliable_text !== null
                              ? data.unavaliable_text
                              : "Reposição do estoque em até 48h"}
                          </span>
                        </div>
                      ) : (
                        <>
                          {data.blocked === 0 || data.is_group ? (
                            <Button
                              title={
                                configuracao?.choose_reward_button_text ??
                                "Escolher prêmio"
                              }
                              onClick={() => {
                                if (data.variation_id === 639) {
                                  setDataGrandCru(data);
                                  setOpenModalGrandCru(true);
                                  return;
                                }
                                if (
                                  configuracao?.choose_reward_show_subcarrossel_as_list &&
                                  data.is_group
                                ) {
                                  openGroupList(data);
                                  setIsGroup(true);
                                  return;
                                }
                                if (
                                  configuracao?.choose_reward_show_subcarrossel_in_other_screen &&
                                  data.is_group
                                ) {
                                  setProductsGroup(data.products);
                                  navigate(`/${path}/grupo`);
                                  return;
                                }

                                if (data.is_group) {
                                  openGroup(data);
                                  setIsGroup(true);
                                } else {
                                  openResgate(data);
                                  setIsGroup(false);
                                }
                              }}
                            />
                          ) : data.blocked_by_level === 1 ? (
                            <div className="voucher-indisponivel-uniclass">
                              <Button
                                title={
                                  data.is_coupon === 1
                                    ? "Cupom Indisponível"
                                    : "Voucher Indisponível"
                                }
                              />
                              <div className="texto">
                                <img src={img2} alt="" />
                                <span>
                                  {text_a}
                                  {text_b ? (
                                    <span className="nivel">{text_b}</span>
                                  ) : null}
                                </span>
                              </div>
                            </div>
                          ) : data.blocked_by_time_block === 1 ? (
                            <div className="voucher-indisponivel-uniclass">
                              <Button
                                title={
                                  data.is_coupon === 1
                                    ? "Cupom Indisponível"
                                    : "Voucher Indisponível"
                                }
                              />
                              <div className="texto">
                                <img src={img6} alt="" />
                                <span>
                                  {text_a}
                                  {text_b ? (
                                    <span className="nivel">{text_b}</span>
                                  ) : null}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div className="voucher-indisponivel">
                              <Button
                                title={
                                  data.is_coupon === 1
                                    ? "Cupom Indisponível"
                                    : "Voucher Indisponível"
                                }
                              />
                              <span>
                                <img src={img1} alt="" />
                                {data.unavaliable_text}
                              </span>
                            </div>
                          )}
                          {data.show_extra_reward_button_on_bottom_item ===
                          1 ? (
                            <div
                              className="visualizarCupom"
                              onClick={() =>
                                verResgates(
                                  configLoja?.user_reward_info.rewards,
                                  data.variation_id
                                )
                              }
                            >
                              <img src={img7} alt="" />

                              <p>
                                {data.is_coupon === 1
                                  ? "Visualizar Cupom"
                                  : "Visualizar Voucher"}
                              </p>
                            </div>
                          ) : configuracao?.disable_know_more !== 1 &&
                            configLoja?.general.use_personalized_know_more ===
                              1 ? (
                            <button
                              className="saiba-mais"
                              onClick={() => {
                                openSaibaMaisNovo(data);
                              }}
                              style={
                                isStone2024
                                  ? {
                                      fontFamily:
                                        "'TonCondensedVF', sans-serif",
                                      fontWeight: "500",
                                      color: "#017F1C",
                                    }
                                  : {}
                              }
                            >
                              Saiba mais
                            </button>
                          ) : (
                            <When
                              expr={
                                data.is_group === false &&
                                configuracao?.disable_stock_number !== 1
                              }
                            >
                              <button
                                className="saiba-mais"
                                onClick={() => {
                                  setModalSaibaMais(true);
                                  if (isGroup === false) {
                                    setIsGroup(false);
                                  }
                                }}
                              >
                                Saiba mais
                              </button>
                            </When>
                          )}
                          <When expr={!configuracao?.disable_stock_number}>
                            <span>Estoque: {data.stock_amount}</span>
                          </When>
                        </>
                      )}
                    </div>
                  </SwiperSlide>
                );
              })
            ) : (
              <p>Nenhum produto disponível.</p>
            )}

            <When expr={configuracao?.choose_reward_adive_text}>
              <p>{configuracao?.choose_reward_adive_text}</p>
            </When>
          </Swiper>

          <ClickAjuda
            onClick={() => {
              navigate("/" + configuracao?.key + "/ajuda");
            }}
          >
            <img
              src={configuracao?.key === "STONE2024" ? ContactUsIcon : img3}
              alt=""
            />

            <span
              className="titulo"
              style={
                configuracao?.key === "STONE2024"
                  ? {
                      fontFamily: "'TonCondensedVF', sans-serif",
                      color: "#000000",
                      fontWeight: "700",
                    }
                  : {}
              }
            >
              Dúvidas?{" "}
              <span
                className="clique"
                style={
                  configuracao?.key === "STONE2024"
                    ? {
                        fontFamily: "'TonCondensedVF', sans-serif",
                        color: "#000000",
                        fontWeight: "700",
                      }
                    : {}
                }
              >
                Clique aqui
              </span>{" "}
              e fale com nosso parceiro YETZ
            </span>
          </ClickAjuda>
        </div>

        <Footer />

        <ModalAguardeEmissao
          isOpen={openModalEmissao}
          handleClose={() => setOpenModalEmissao(false)}
          cor={configuracao?.background}
          prod={produtoResgate}
          url={configuracao?.key}
          user={user?.user_id}
          texto={configuracao?.confirm_choose_modal_text}
          textoAguarde={configuracao?.choose_reward_modal_text}
        />
      </Container>
      <ModalGrupo
        isOpen={opendGroup}
        handleClose={() => setOpendGroup(false)}
        handleSaibaMais={() => {
          setOpendGroup(false);
          setModalSaibaMais(true);
        }}
        prod={productGroup}
        openResgate={openResgate}
        disableSaibaMais={configuracao?.disable_stock_number}
      />
      <ModalSaibaMais
        isOpen={modalSaibaMais}
        handleClose={() => {
          if (isGroup === false) {
            setModalSaibaMais(false);
          } else {
            setOpendGroup(true);
            setModalSaibaMais(false);
          }
        }}
        prod={productGroup}
        openResgate={openResgate}
      />

      <ModalSaibaMaisNovo
        isOpen={modalSaibaMaisNovo}
        cor={configuracao?.background}
        handleClose={() => setModalSaibaMaisNovo(false)}
        prod={produtoSaibaMaisNovo}
        openResgate={openResgate}
      />

      <ModalAviso
        isOpen={active}
        mensagem={mensagem}
        handleClose={() => setActive(false)}
      />

      <ModalGrupoLista
        isOpen={opendGroupList}
        handleClose={() => setOpendGroupList(false)}
        handleSaibaMais={openSaibaMaisNovo}
        prod={productGroup}
        openResgate={openResgate}
        configuracao={configuracao}
      />
      <MenuLateral
        isOpen={activeMenu}
        handleClose={() => setActiveMenu(false)}
        acao={configuracao?.name}
        url={configuracao?.key}
      />

      <Modal
        open={modalNovidade}
        handleClose={() => setModalNovidade(!modalNovidade)}
      >
        <ModalNovidade handleClose={() => setModalNovidade(!modalNovidade)} />
      </Modal>

      <ModalGrandCru
        isOpen={openModalGrandCru}
        clickContinue={() => {
          setOpenModalGrandCru(false);
          openResgate(dataGrandCru);
          setIsGroup(false);
        }}
        handleClose={() => setOpenModalGrandCru(false)}
      />
    </>
  );
}
