import { useLocation } from "react-router-dom";
import { useState, useEffect, lazy, Suspense } from "react";
import { LoginWithAuth } from "../../pages/acao/LoginWithAuth/index.js";

export const AuthHandler = () => {  
  const location = useLocation();
  const [authToken, setAuthToken] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("auth_token");
    if (token) {
      setAuthToken(token);
    }
  }, [location.search]);

  if (authToken) {
    return <LoginWithAuth />;
  } else {
    const Login = lazy(() => import("../../pages/acao/login").then(module => ({ default: module.Login })));
    
    return (
      <Suspense>
        <Login />
      </Suspense>
    );
  }
};
