// React and hooks
import React, { useRef, useEffect, useState, useMemo, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Services and utilities
import { api } from "../../../services/api";
import Cookies from "universal-cookie";

// Assets
import LogoItauLogin from "../../../assets/img/LogoItauLogin.svg";
import BenefitsLabel from "../../../assets/img/BenefitsLabel.svg";

// Components and styles
import CircularProgress from "./CircularProgress";
import ErrorLogin from "../../../components/ErrorLogin";
import styles from "./styles";

// Context
import { useAcaoContext } from "../../../contexts/acaoContext";


export const LoginWithAuth = () => {
    const [showAnimation, setShowAnimation] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState("#000D3C");
    const [applyTransition, setApplyTransition] = useState(false);
    const [responseStatus, setResponseStatus] = useState(null);
    const location = useLocation();
    const keyActionRef = useRef(null);
    const redirectOldFlow = useRef(null);

    const navigate = useNavigate();

    const { configuracao } = useAcaoContext();

    const cookies = useMemo(() => new Cookies(), []);

    const saveUserData = useCallback((data) => {
        cookies.remove("yp_user", { path: "/", sameSite: "None", secure: true });
        cookies.set("yp_user", JSON.stringify(data), {
            path: "/",
            sameSite: "None",
            secure: true,
        });
        localStorage.setItem("yp_user", JSON.stringify(data));

        cookies.remove("yp_dadosLogin", { path: "/", sameSite: "None", secure: true });
        cookies.set("yp_dadosLogin", JSON.stringify(data), { path: "/", sameSite: "None", secure: true });
        localStorage.setItem("yp_dadosLogin", JSON.stringify(data));
        
    }, [cookies]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowAnimation(true);
            setApplyTransition(true);
            setBackgroundColor("#000");
        }, 800);

        const loadingTimer = setTimeout(() => {
            setShowLoading(true);
        }, 2500);

        return () => {
            clearTimeout(timer);
            clearTimeout(loadingTimer);
        };
    }, []);


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const search_key = queryParams.get("origin_benefit_click_id");

        if (search_key) {
            cookies.set("yp_paramProduto", search_key, { path: "/", sameSite: "None", secure: true });
            localStorage.setItem("yp_paramProduto", search_key);
        }
    }, [location, cookies]);

    useEffect(() => {
        const action_key = location.pathname.split("/").pop();
        keyActionRef.current = action_key;
        const queryParams = new URLSearchParams(location.search);
        const auth_token = queryParams.get("auth_token");

        if (!auth_token || !action_key) {
            navigate("/ops");
        }

        const requestBody = {
            auth_token: auth_token,
            action_key: action_key,
            search_key: localStorage.getItem("yp_paramProduto") || null
        };

        const sendRequest = async () => {
            try {

                await new Promise(resolve => setTimeout(resolve, 2300));

                const response = await api.post("/client/validate-access", requestBody, { disableAlert: true });
                const data = response.data;
                setResponseStatus(response.status);

                if (response.status === 200) {
                    saveUserData(data);

                    if (data.rewards >= data.max_rewards) {
                        navigate("/" + keyActionRef.current + "/finalizacao");
                    } else {
                        navigate("/" + keyActionRef.current + "/loja");
                    }
                }
            } catch (error) {

                if (error?.redirect_old_flow === true) {
                    redirectOldFlow.current = error.redirect_old_flow;
                }
                setResponseStatus(error.status);
            }
        };

        sendRequest();

    }, [location, navigate, saveUserData]);

    if (redirectOldFlow.current) {
        const queryParams = new URLSearchParams(location.search);
        const originBenefitClickId = queryParams.get("origin_benefit_click_id");
        const newUrl = "/" + keyActionRef.current + (originBenefitClickId ? "?origin_benefit_click_id=" + originBenefitClickId : "");
        window.location.href = newUrl;
    }

    if (responseStatus !== 200 && responseStatus !== null && !redirectOldFlow.current) {
        return <ErrorLogin configuracao={configuracao} />;
    }

    return (
        <>
            <div style={{
                ...styles.container,
                backgroundColor: backgroundColor,
                transition: applyTransition ? "background-color 1s ease-in-out" : "none"
            }}>
                {!showLoading && (
                    <div style={{
                        ...styles.content,
                    }}>
                        <div style={{
                            ...styles.logoWrapper,
                            backgroundColor: backgroundColor,
                            transform: showAnimation ? 'translateX(-40px)' : 'translateX(0)',
                            transition: applyTransition ? 'transform 0.5s ease-in-out, background-color 1s ease-in-out' : 'transform 0.5s ease-in-out',
                        }}>
                            <img src={LogoItauLogin} alt="Logo Itaú" style={styles.logo} />
                        </div>
                        <img src={BenefitsLabel} alt="Benefícios" style={{
                            ...styles.benefitsLabel,
                            transform: showAnimation ? 'translateX(40px)' : 'translateX(0)',
                            opacity: showAnimation ? 1 : 0,
                            transition: 'transform 0.4s ease-in-out, opacity 0.1s ease-in-out',
                            transitionDelay: showAnimation ? '0.2s' : '0s',
                        }} />
                    </div>
                )}

                {showLoading && (<CircularProgress />)}

            </div>

            <style>
                {`
                    @keyframes spin {
                        0% { transform: rotate(0deg); }
                        100% { transform: rotate(360deg); }
                    }
                `}
            </style>
        </>
    );
};
