import styled from "styled-components";

export const Container = styled.button`
  border: none;
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 383px;
  height: 40px;
  cursor: pointer;
  background: ${(props) => props.button_color || "#f17e21"} !important;
  border-radius: ${(props) => props.borderRadius || "100px"};
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.textColor || "#ffffff"};
  font-family: ${(props) => props.fontFamily || ""};
  transition: all 0.5s;

  &:disabled {
    background: #c4c4c4;
    color: #5e5f5f;
    cursor: not-allowed;
    transition: all 0.5s;
  }

  @media (max-width: 1211px) {
    width: 100%;
    height: 34px;
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
  }
`;
