import styled from "styled-components";
import { Container as Button } from "../../components/button/styles";
import { Container as CheckBox } from "../../components/checkbox/styles";

import img2 from "../../assets/img/check.svg";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  position: fixed;
  background-color: transparent;
  z-index: -1;
  pointer-events: none;
  transition: all 0.5s;

  &[data-show="true"] {
    background: rgba(0, 0, 0, 0.7);
    pointer-events: all;
    z-index: 9999;
    transition: all 0.5s;

    .content {
      top: 50% !important;
      transition: all 0.5s;
    }
  }

  &.faleConosco {
    padding: 0;
    .content {
      padding: 0;
    }
    .topo-titulo {
      background: ${({ cor }) => cor || "#6f3e98"};
      padding: 1.5rem 2.5rem;
      border-radius: 8px 8px 0 0;
      h3 {
        font-weight: 600;
        font-size: 22px;
        line-height: 33px;
        color: #ffffff;
        margin: 0;
      }
      img {
        position: absolute;
        top: 1.5rem;
        right: 2.5rem;
        cursor: pointer;
      }
      .img-titulo {
        display: flex;
        align-items: center;
        grid-gap: 20px;
      }
    }
    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1.5rem 2.5rem;
      .container-form {
        width: 100%;
      }
      .math {
        display: flex;
        align-items: center;
        grid-gap: 10px;
      }
      button {
        background: ${({ cor }) => cor || "#6f3e98"} !important;
        width: 100%;
        margin: 10px auto;
        background-color: #713d97;
        color: #fff;
      }
      input {
        background: #ffffff;
        border: 1px solid #cecece;
        box-sizing: border-box;
        border-radius: 4px;
        height: 43px;
        padding: 0 20px;
        margin-bottom: 10px;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #565565;
        font-family: "Poppins-Regular";
      }
      textarea {
        background: #ffffff;
        border: 1px solid #cecece;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 0 20px;
        margin-bottom: 10px;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #565565;
        width: 100%;
        height: 100px;
        padding-top: 10px;
        font-family: "Poppins-Regular";
      }
    }
    input[type="text"],
    input[type="tel"],
    input[type="email"] {
      width: 100%;
    }

    &[data-show="true"] {
      background: rgba(0, 0, 0, 0.4);
    }
    .msg-footer {
      text-align: center;
      font-size: 14px;
      margin: 10px;
    }
  }

  &.avisoSucesso,
  &.avisoErro {
    padding: 0;
    .content {
      padding: 0;
      width: 469px;
      .body {
        padding: 1.5rem 2.5rem;
        .titulo {
          flex-direction: column;
          text-align: center;
          display: flex;
          align-items: center;
          h2 {
            font-weight: 700;
            font-size: 24px;
            line-height: 120%;
            text-align: center;
            letter-spacing: 0.02em;
            color: #0f172b;
          }
          span {
            font-weight: 500;
            font-size: 15px;
            line-height: 140%;
            text-align: center;
            color: rgba(0, 0, 0, 0.6);
            width: 330px;
          }
          .span-ticket {
            margin: 40px 0 0;
          }
          .parag-ticket {
            width: 327px;
            height: 40px;
            border-radius: 100px;
            border: 1px solid var(--cinza-cinza-04, #d4d4d4);
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .img-titulo {
        background: ${({ cor }) => cor || "#6f3e98"};
        border-radius: 8px 8px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 39px 0 30px 0;
      }
      button {
        border-radius: 6px;
        margin: 20px 0 auto;
      }
    }
    &[data-show="true"] {
      background: rgba(0, 0, 0, 0.4);
    }
  }

  .content {
    z-index: 99999;
    position: absolute;
    top: -5000px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 518px;
    max-height: 90vh;
    padding: 1.5rem 2.5rem;
    background-color: #fff;
    border-radius: 8px;
    transition: all 0.5s;
    &.aviso {
      width: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px;
      height: auto;
      h2 {
        text-align: center;
      }
      button {
        margin: 0 auto;
        width: 100%;
      }
    }
    &.resgate {
      padding-top: 150px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      position: relative;
      .close-modal {
        background: transparent;
        border: none;
        position: absolute;
        top: 20px;
        right: 20px;
        padding: 0px;
        width: auto;
        z-index: 999;
        img {
          width: 14px;
          position: static;
          transform: unset;
        }
      }
      .close {
        top: 20px;
        right: 30px;
        cursor: pointer;
      }
      h3 {
        color: ${({ cor }) => cor || "#000"};
        width: 314px;
        margin-bottom: 0;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
      }
      p {
        color: #2e3337;
        font-weight: 700;
        font-size: 18px;
        line-height: 120%;

        &.depois {
          cursor: pointer;
          font-size: 14px;
          line-height: 150%;
          color: #5e5f5f;
        }
      }
      img {
        position: absolute;
        top: -50px;
        right: 50%;
        transform: translateX(50%);
        width: 292px;
        &.loader {
          width: 50px;
          position: static;
          transform: none;
        }
      }
      .textoExtra {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        color: #575757;
        margin-top: 16px;
      }
      .textBody {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        color: #5e5f5f;
      }
    }

    &.group {
      background: #ffffff;
      width: 800px;
      height: 745px;
      border-radius: 30px;
    }

    &.passo-a-passo {
      padding: 0px;
      width: 493px;
      background-color: transparent;
      height: 100%;
      position: relative;
      button {
        &.close-modal {
          position: absolute;
          top: 15px;
          right: 15px;
          background-color: transparent;
          border: none;
          cursor: pointer;
        }
      }
      iframe {
        width: 100%;
        height: 100%;
        border-radius: 30px;
        border: none;
      }
    }
    &.iframe-voucher {
      padding: 0px;
      width: 400px;
      height: 100%;
      position: relative;
      button {
        &.close-modal {
          position: absolute;
          top: 15px;
          right: 15px;
          background-color: transparent;
          border: none;
          cursor: pointer;
        }
      }
      iframe {
        width: 100%;
        height: 100%;
        border: none;
      }
      .loader {
        height: 100%;
        display: flex;
        justify-content: center;
        img {
          width: 100px;
        }
      }
    }
    &.modalGrandCru {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 1.5rem;
      position: relative;
      .close-modal {
        background: transparent;
        border: none;
        position: absolute;
        top: 20px;
        right: 20px;
        padding: 0px;
        width: auto;
        z-index: 999;
        margin: 0;
        img {
          width: 14px;
          position: static;
          transform: unset;
        }
      }
      .titulo {
        h2 {
          color: #0e0045;
          font-size: 24px;
        }
      }
      span {
        color: #5e5f5f;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
      }
      p {
        color: #2e3337;
        font-weight: 700;
        font-size: 18px;
        line-height: 120%;

        &.depois {
          cursor: pointer;
          font-size: 14px;
          line-height: 150%;
          color: #5e5f5f;
        }
      }
      button {
        margin-top: 40px;
      }
    }
  }
  @media (max-width: 1211px) {
    &.faleConosco {
      &[data-show="true"] {
        .content {
          overflow-y: auto;
          width: 100%;
          height: 100%;
          overflow-y: auto;
          display: block;
          text-align: center;
          height: 100%;
          margin-top: 30px;
          top: 0 !important;
          left: 0 !important;
          transform: none;
          max-height: 100%;
          img {
            width: 20px;
            height: 20px;
            right: 20px;
          }
        }
      }
    }

    .content {
      width: 90% !important;
      &.resgate {
        h3 {
          font-size: 22px;
        }
        .textBody {
          font-size: 14px;
        }
        p {
          font-size: 16px;
        }
      }
    }
  }
`;

export const CodigoSms = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  position: fixed;
  background-color: transparent;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s;

  .container-enviar {
    margin-top: 14px;
    button {
      width: 328px;
    }
  }

  &[data-show="true"] {
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
    pointer-events: all;
    opacity: 1;
    z-index: 100;
    transition: all 0.5s;
    &.backdrop-saibaMais {
      z-index: 1000;
    }
  }

  .novo-codigo {
    opacity: 1;
    cursor: pointer;
    &.active {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    width: 450px;
    padding: 20px;
    background: #ffffff;
    border-radius: 30px;

    &.grupoLista {
      display: flex;
      flex-direction: column;
      width: 800px;
      position: relative;
      overflow: auto;
      padding: 30px 70px;
      align-items: baseline;
      &::-webkit-scrollbar {
        -webkit-appearance: none;
      }

      &::-webkit-scrollbar:vertical {
        width: 5px;
      }

      &::-webkit-scrollbar:horizontal {
        height: 12px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(89, 92, 118, 0.5);
      }

      &::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.1);
      }
      .close-modal {
        background: transparent;
        border: none;
        position: absolute;
        top: 20px;
        right: 20px;
        padding: 0px;
        width: auto;
        z-index: 999;
        cursor: pointer;
        img {
          width: 14px;
          position: static;
          transform: unset;
        }
      }
      ${CheckBox} {
        align-self: center;
      }
      .titulo {
        display: flex;
        align-items: center;
        grid-gap: 10px;
        margin-bottom: 30px;
        h3 {
          color: #0e0045;
          font-size: 20px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;
          text-transform: uppercase;
          margin: 0;
        }
        img {
          margin: 0;
        }
      }
      .btns {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
        p {
          color: #5e5f5f;
          font-weight: 700;
          font-size: 16px;
          line-height: 150%;
          &.depois {
            cursor: pointer;
          }
        }
        button {
          margin-bottom: 0;
        }
      }
      .box {
        display: grid;
        grid-template-columns: 1fr 2fr 2fr 20px;
        border-radius: 5px;
        border: 1px solid #5e5f5f;
        background: #fff;
        align-items: center;
        grid-gap: 10px;
        padding: 15px 20px;
        margin-bottom: 12px;
        width: 100%;
        height: auto;
        cursor: pointer;
        &.active {
          border: 1px solid #0e0045;
          background: #e3e3e3;
        }
        &.box-indisponivel {
          border-radius: 5px;
          background: #f2f2f2;
          border: none;
          span {
            color: #5e5f5f;
          }
          .indisponivel {
            border-radius: 8px;
            background: #d9d9d9;
            width: 198px;
            width: 198px;
            padding: 6px 8px;
            grid-column: span 2;
            justify-self: end;
            p {
              color: #5e5f5f;
              text-align: initial;
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: 150%;
              margin: 0;
            }
            strong {
              font-weight: 900;
              color: rgb(65, 65, 65);
            }
            span {
              color: #5e5f5f;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
            }
          }
        }
        .pretty.p-default:not(.p-fill)
          input:checked
          ~ .state.p-primary-o
          label:after {
          background-color: ${({ cor }) => cor || "#6f3e98"} !important;
        }
        .pretty input:checked ~ .state.p-primary-o label:before,
        .pretty.p-toggle .state.p-primary-o label:before {
          border-color: ${({ cor }) => cor || "#6f3e98"} !important;
        }
        span {
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
          margin: 0;
          text-align: initial;
        }
      }
      .saiba-mais {
        border: none;
        background: transparent;
        color: #0e0045;
        font-size: 14px;
        font-style: normal;
        font-weight: 900;
        line-height: 18px;
        position: relative;
        width: auto !important;
        padding: 0px;
        height: 30px;
        cursor: pointer;
        display: flex;
        align-items: center;
        grid-gap: 10px;
        margin: 0;
        img {
          margin: 0;
        }
        &:before {
          content: none;
        }
      }
      .img-produto {
        height: 62px;
        margin: 0;
      }
    }

    &.saibaMaisNovo {
      overflow: auto;
      /* display: block; */
      text-align: center;
      position: relative;
      max-height: 80%;
      padding-right: 10px;
      .close-modal {
        background: transparent;
        border: none;
        position: absolute;
        top: 20px;
        right: 30px;
        padding: 0px;
        width: auto;
        z-index: 999;
        cursor: pointer;
        img {
          width: 14px;
          position: static;
          transform: unset;
        }
      }
      .close {
        top: 20px;
        right: 30px;
        cursor: pointer;
      }
      h3 {
        color: ${({ cor }) => cor || "#000"};
        width: 314px;
        margin-bottom: 0;
        margin-left: auto;
        margin-right: auto;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        text-transform: none;
      }
      button {
        margin-left: auto;
        margin-right: auto;
      }
      p {
        color: #5e5f5f;
        font-size: 16px;
        line-height: 150%;
        &.depois {
          cursor: pointer;
        }
        strong {
          font-family: "Poppins-Bold" !important;
          overflow-wrap: anywhere;
        }
        li {
          list-style-type: none;
          position: relative;
          text-align: initial;
          margin-bottom: 10px;
          &:before {
            content: url(${img2});
            position: absolute;
            top: 5px;
            left: -35px;
          }
        }
      }
      span {
        color: inherit;
        font-weight: inherit;
        line-height: inherit;
        display: inherit;
        text-align: inherit;
        margin-bottom: inherit;
        overflow-wrap: anywhere;
      }
      img {
        width: 292px;
        margin-right: auto;
        margin-left: auto;
      }
      .conteudo-saiba {
        height: 100%;
        overflow: auto;
        padding-right: 10px;
        &::-webkit-scrollbar {
          -webkit-appearance: none;
        }

        &::-webkit-scrollbar:vertical {
          width: 5px;
        }

        &::-webkit-scrollbar:horizontal {
          height: 12px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: rgba(89, 92, 118, 0.5);
        }

        &::-webkit-scrollbar-track {
          background-color: rgba(255, 255, 255, 0.1);
        }
      }
    }

    &.filtroCarteira {
      display: flex;
      flex-direction: column;
      width: 395px;
      position: relative;
      overflow: auto;
      padding: 30px 24px;
      align-items: baseline;
      border-radius: 10px;
      &::-webkit-scrollbar {
        -webkit-appearance: none;
      }

      &::-webkit-scrollbar:vertical {
        width: 5px;
      }

      &::-webkit-scrollbar:horizontal {
        height: 12px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(89, 92, 118, 0.5);
      }

      &::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.1);
      }
      .close-modal {
        background: transparent;
        border: none;
        position: absolute;
        top: 20px;
        right: 20px;
        padding: 0px;
        width: auto;
        z-index: 999;
        cursor: pointer;
        img {
          width: 14px;
          position: static;
          transform: unset;
        }
      }
      .btns {
        width: 100%;
        button {
          width: 327px;
          border-radius: 10px;
          margin-left: auto;
          margin-right: auto;
        }
      }
      .titulo {
        border-bottom: 1px solid ${({ cor }) => cor || "#6f3e98"};
        width: 100%;
        padding-bottom: 10px;
        h3 {
          margin-bottom: 0;
        }
      }
      h3 {
        text-transform: capitalize;
        font-size: 14px;
        margin-bottom: 20px;
      }
      .filtroSecao {
        border-bottom: 1px solid #efefef;
        width: 100%;
        padding-bottom: 30px;
        margin-bottom: 10px;
        .dupla {
          display: flex;
          grid-gap: 15px;
          label {
            color: rgba(0, 0, 0, 0.5);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
          }
          input[type="text"] {
            border: 1px solid rgba(157, 157, 157, 1);
            width: 130px;
            height: 40px;
            border-radius: 10px;
            margin: 0;
            color: rgba(0, 0, 0, 0.5);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-align: initial;
            padding-left: 10px;
          }
        }
        .pretty.p-round .state label:after,
        .pretty.p-round .state label:before {
          top: 0;
        }
      }
    }

    .campoCelular {
      background: #e3e3e3;
      border-radius: 100px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      box-sizing: border-box;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      text-transform: uppercase;
      color: #5e5f5f;
      width: 80%;
      padding: 10px;
      margin: 0 auto;
      display: block;
      text-align: center;
    }

    .timer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #5e5f5f;
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 10px;
      p {
        margin: 0px !important;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        text-align: center;
        letter-spacing: 0.04em;
        color: #5e5f5f;
      }
      img {
        margin-bottom: 0 !important;
      }
    }

    h4 {
      color: #ffffff;
      font-weight: 600;
      font-size: 16px;
      line-height: 120%;
      text-align: center;
    }

    img {
      display: block;
      margin-bottom: 33px;
    }

    h3 {
      font-weight: 900;
      font-size: 20px;
      line-height: 24px;
      text-transform: uppercase;
      color: ${({ cor }) => cor || "#6f3e98"};
      strong {
        font-weight: bold;
      }
      margin-bottom: 0;
    }
    h5 {
      font-weight: 900;
      font-size: 22px;
      line-height: 27px;
      text-align: center;
      color: #ffffff;
      margin-top: 60px;
    }

    span {
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      display: block;
      text-align: center;
      color: #ffffff;
      margin-bottom: 8px;
      &.solicitaNovo {
        color: #000d3c;
        font-weight: 900;
        cursor: pointer;
        margin-top: 20px;
      }
    }

    /* .container-inputs {
      display: flex !important; */
    input[type="text"],
    input[type="password"] {
      background-color: #ffffff;
      border-radius: 100px;
      text-align: center;
      width: 300px;
      height: 50px;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.5);
      border: none;
      margin-bottom: 40px;
    }

    small {
      display: block;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 140%;
      text-align: center;
      color: #fff;
      margin-bottom: 34px;
      strong {
        font-weight: bold;
      }
    }

    p {
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #000000;
    }

    .container-inputs {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      align-items: center;
      justify-content: space-between;
      width: 343px;
      position: relative;
      margin-top: 20px;
      &.error {
        input {
          border: 2px solid #ff0000;
          transition: all 0.5s;
        }
        .container-erro {
          display: flex;
          transition: all 0.5s;
        }
      }

      .container-erro {
        grid-column: span 6;
        justify-content: center;
        display: none;
        align-items: center;
        margin-top: 13px;
        transition: all 0.5s;
        margin-bottom: 20px;
        img {
          margin-bottom: 0px;
          margin-right: 8px;
        }
        h5 {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: normal;
          text-align: center;
          color: #ff000f;
          white-space: nowrap;
          margin: 0px 5px;
        }
      }
      input {
        background: #ffffff;
        border: 1px solid #9cabb8;
        border-radius: 4px;
        width: 51px;
        height: 55px;

        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;

        color: #000000;
        text-align: center;
        transition: all 0.5s;
      }
    }
    .container-novo-sms {
      display: flex;
      flex-direction: column;
      align-items: center;
      grid-gap: 10px;
    }
  }

  button {
    margin-bottom: 20px;
  }

  @media (max-width: 1211px) {
    .container-enviar {
      button {
        width: 299px;
      }
    }

    &[data-show="true"] {
      .content {
        bottom: 0px;
        transition: all 0.5s;
        &.codigoVerifica {
          top: 0;
        }
      }
    }

    .container-enviar {
      button {
        max-width: 299px;
        width: 100%;
      }
    }

    .content {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      display: block;
      text-align: center;
      height: auto;
      position: fixed;
      bottom: -100vh;
      border-radius: 20px 20px 0px 0px;
      transition: all 0.5s;
      .logo {
        width: 193px;
        margin: 0 auto;
        margin-bottom: 33px;
      }
      /* img {
        width: 100%;
      } */
      &.resgate {
        border-radius: 40px;
        padding: 20px;
      }

      &.grupoLista {
        padding: 20px 10px;
        position: fixed;
        width: 100%;
        overflow: auto;
        max-height: 100vh;
        .box {
          padding: 10px 5px;
          grid-template-columns: 100px 2fr 20px;
          position: relative;
          padding: 10px 20px 30px 10px;
          align-items: flex-start;
          span {
            font-size: 14px;
          }
          .saiba-mais {
            position: absolute;
            bottom: 10px;
            left: 120px;
          }
          &.box-indisponivel {
            height: auto;
            min-height: 150px;
            grid-template-columns: 1fr 2fr;
            .indisponivel {
              position: absolute;
              bottom: 10px;
              left: 120px;
            }
            .img-produto {
              align-self: flex-end;
            }
          }
        }
        .titulo {
          margin-bottom: 10px;
          margin-top: 20px;
          h3 {
            font-size: 18px;
            text-align: initial;
          }
        }
        .btns {
          margin-top: 0;
          button {
            width: 290px;
            margin-bottom: 0;
            font-size: 14px;
            margin-top: 10px;
          }
          p {
            font-size: 14px;
            margin-bottom: 0;
          }
        }
      }

      &.saibaMaisNovo {
        overflow-y: auto;
        height: calc(100vh - 30px);
        width: 99% !important;
        max-height: 100vh !important;
        margin-top: 20px;
        padding: 1rem;
        display: block;
        img {
          width: 215px;
        }
      }

      &.filtroCarteira {
        text-align: initial;
        position: fixed;
        width: 100%;
        overflow: auto;
        max-height: 100vh;
        border-radius: 20px 20px 0px 0px;
        .btns {
          button {
            width: 100%;
          }
        }
      }

      .container-inputs {
        width: 100%;
        grid-gap: 5px;
        input {
          width: 100%;
          height: 40px;
        }
      }
    }
  }
`;

export const Envio = styled.div`
  display: flex;
  grid-gap: 10px;
  cursor: pointer;
  border-top: 1px solid #5e5f5f;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  margin-top: 20px;
  span {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 150% !important;
    color: #6f3e98 !important;
    margin-bottom: 0 !important;
    text-align: initial !important;
    display: block;
  }
  img {
    margin-bottom: 0 !important;
  }
`;

export const StyledModalGrupo = styled.div`
  &[data-show="true"] {
    background: rgba(0, 0, 0, 0.7);
    pointer-events: all;
    z-index: 9999;
    transition: all 0.5s;

    .content-group {
      top: 50% !important;
      transition: all 0.5s;
    }
  }

  .modal-header {
    padding-top: 80px;
    margin-bottom: 36px;
    .modal-title {
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      color: #6f3e98;
      display: flex;
      justify-content: center;
      margin: 0px;
      img {
        display: block;
        margin-right: 15px;
      }
    }
  }

  .container-swiper {
    position: relative;
    width: 496px;
    margin: 0 auto;
    .container-btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      position: absolute;
      top: 120px;

      left: 0px;
      div {
        cursor: pointer;
      }
    }
  }

  .swiper {
    width: 308px;
    .swiper-pagination-bullet {
      background: #5e5f5f;
      opacity: 1;
      border-radius: 10px;
      width: 70px;
      height: 4px;
      &.swiper-pagination-bullet-active {
        background: #f17e21;
      }
    }
  }

  .premio {
    .container-img {
      margin-bottom: 21px;
      text-align: center;
      img {
        max-height: 241px;
        /* width: 100%; */
        border-radius: 10px;
      }
    }

    h4 {
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      line-height: 150%;
      text-align: center;
      color: #5e5f5f;
      margin: 0px;
      margin-bottom: 15px;
    }

    ${Button} {
      width: 290px;
      margin: 0 auto;
      margin-bottom: 21px;
    }

    button {
      &.saiba-mais {
        background: transparent;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 18px;
        color: #5e5f5f;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 0 auto;
        margin-bottom: 60px;
        img {
          display: block;
          margin-right: 12px;
        }
      }
    }
  }

  .close-modal {
    position: absolute;
    top: 54px;
    right: 46px;
    border: none;
    background: transparent;
    cursor: pointer;
  }

  @media (max-width: 1366px) {
    &.content {
      &.group {
        height: auto;
        .modal-header {
          padding: 0px;
          margin-bottom: 16px;
        }
      }
    }
  }

  @media (max-width: 1211px) {
    &.content {
      &.group {
        width: 90%;
        padding: 20px;
        .modal-header {
          .modal-title {
            justify-content: flex-start;
            img {
              margin-right: 45px;
            }
          }
        }

        .container-swiper {
          width: 100%;
        }

        .close-modal {
          top: 32px;
          right: 20px;
          cursor: pointer;
        }
      }
    }
  }
`;

export const ContainerIframe = styled.div`
  height: 100%;
`;
